import React, {useEffect, useState} from "preact/compat"
import {h} from "preact"
import {FileInput} from "../components/FileInput"
import {TimeInput} from "../components/TimeInput"
import {TextArea} from "../components/TextArea"
import {TextInput} from "../components/TextInput"
import {Button} from "../components/Button"
import {encryptVulnerabilityReport} from "../actions/encrypt-vulnerability-report"
import {errorMessage} from "../actions/errors"
import {Network} from "../App"

type VulnerabilityReportEncryptProps = {
    network: Network
}
export const VulnerabilityReportEncrypt = (props: VulnerabilityReportEncryptProps) => {
    const [cve, setCve] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [files, setFiles] = useState<FileList>()
    const [decryptionTime, setDecryptionTime] = useState(Date.now())
    const [error, setError] = useState("")

    const [ciphertext, setCiphertext] = useState("")

    const encrypt = async () => {
        try {
            setCiphertext(await encryptVulnerabilityReport(props.network, {
                title,
                description,
                cve,
                files,
                decryptionTime
            }))
        } catch (err) {
            console.error(err)
            setError(errorMessage(err))
        }
    }

    // clear the ciphertext if the network changes to prompt the user to hit `encrypt` again
    useEffect(() => {
        setCiphertext("")
    }, [props.network])

    return (
        <div className="row light-bg">
            <div className="row p-0" id="errors">
                <p className="m-0 p-0" id="error">{error}</p>
            </div>
            <div class={"col-12 col-sm-6"}>
                <div className="row mb-6 p-3">
                    <TimeInput
                        label={"Decryption time"}
                        value={decryptionTime}
                        onChange={setDecryptionTime}
                    />
                </div>
                <div className="row mb-6 p-3">
                    <TextInput
                        label={"Title"}
                        value={title}
                        onChange={setTitle}
                    />
                </div>
                <div className="row mb-6 px-3">
                    <TextArea
                        label={"Description"}
                        value={description}
                        onChange={setDescription}
                        rows={15}
                    />
                </div>
                <div className="row mb-6 px-3 py-2">
                    Optional fields:
                </div>
                <div className="row mb-6 px-3">
                    <TextInput
                        label={"CVE"}
                        value={cve}
                        onChange={setCve}
                    />
                </div>
                <div className="row mb-6 px-3">
                    <FileInput
                        label={"Upload a file to encrypt"}
                        onChange={setFiles}
                    />
                </div>
            </div>
            <div className={"col-12 col-sm-6 p-0"}>
                <div className="row mb-6 align-items-end p-3">
                    <div className={"p-3"}>
                        <Button
                            onClick={encrypt}
                            text={"Encrypt"}
                        />
                    </div>
                </div>
                <div className="row mb-6 p-3">
                    <TextArea
                        label={"Ciphertext"}
                        value={ciphertext}
                        onChange={setCiphertext}
                    />
                </div>
            </div>
        </div>
    )
}