import {vulnerabilityEncryptionSchema} from "../schema/vulnerability-encryption-schema"
import {HttpChainClient, roundAt, timelockEncrypt} from "tlock-js"
import {fileAsBuffer} from "./file-utils"
import {Network} from "../App"
import {fastnet, quicknet, testnetQuicknet, testnetUnchained} from "./client-utils"

export async function encryptVulnerabilityReport(network: Network, form: unknown): Promise<string> {
    const input = await vulnerabilityEncryptionSchema.validate(form)
    if (input.files && input.files.length > 1) {
        throw Error("Right now only a single file is supported!")
    }

    const inputFile = input.files?.item(0)
    let file = null
    if (inputFile) {
        const fileBuf = await fileAsBuffer(inputFile)
        const fileBase64 = fileBuf.toString("base64")

        file = {
            name: inputFile.name,
            content: fileBase64
        }
    }

    const plaintext = JSON.stringify({
        title: input.title,
        description: input.description,
        cve: input.cve,
        file
    })

    let client: HttpChainClient
    switch (network) {
        case "quicknet":
            client = quicknet()
            break
        case "fastnet":
            client = fastnet()
            break
        case "quicknet-t":
            client = testnetQuicknet()
            break
        case "testnet-unchained-3s":
            client = testnetUnchained()
            break
        default:
            throw Error("unknown network")
    }
    const roundNumber = roundAt(input.decryptionTime, await client.chain().info())
    return await timelockEncrypt(roundNumber, Buffer.from(plaintext), client)
}
