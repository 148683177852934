import * as yup from "yup"

export const vulnerabilityEncryptionSchema = yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
    cve: yup.string()
        .nullable(true)
        .optional(),
    files: yup.mixed<FileList>()
        .nullable(true)
        .optional(),
    decryptionTime: yup.number()
        .positive().required()
        .positive()
        .required()
}).required()

// stolen from https://regexland.com/base64/
const base64Regex = /^(?:[A-Za-z\d+/]{4})*(?:[A-Za-z\d+/]{3}=|[A-Za-z\d+/]{2}==)?$/

const fileSchema = yup.object({
    name: yup.string()
        .required(),
    content: yup.string()
        .matches(base64Regex)
        .required()
})

export const vulnerabilityDecryptionSchema = yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
    cve: yup.string()
        .nullable(true)
        .optional(),
    file: fileSchema
        .nullable(true)
        .optional(),
}).required()
